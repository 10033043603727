import { Link } from 'src/components/link'
import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'

export default () => (
  <Wrapper subtitle='Seite nicht gefunden'>
    <h1>Seite nicht gefunden</h1>

    <div>
      Die von Ihnen angeforderte Seite wurde nicht gefunden. <Link to='/' className='link'>Hier geht es zur Startseite</Link>
    </div>
  </Wrapper>
)
